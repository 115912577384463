import * as React from "react";
import "./blades.css";
import Arsenal from "./Arsenal";
// import Blade from "./Blade";

interface BladeContextData {
  register: any;
  deregister: any;
  blades: any[];
}

export const BladeContext = React.createContext({} as BladeContextData);

export const useBladeContext = () => {
  return React.useContext(BladeContext);
};

export interface BladeData {
  id: string;
  component: JSX.Element | Element;
}

const initialState = { blades: [] };

function reducer(state, action) {
  switch (action.type) {
    case "register":
      return { blades: [...state.blades, action.value] };
    case "unregister":
      return { blades: state.blades.filter(f => f.id !== action.value.id) };
    default:
      return state;
  }
}

export const BladeWorks = ({ children }) => {
  const bladeWorksRef = React.useRef(null);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const registerBlade = bladeData => {
    dispatch({ type: "register", value: bladeData });
  };

  const unregisterBlade = bladeData => {
    dispatch({ type: "unregister", value: bladeData });
  };

  return (
    <div className="blade-container">
      <div className="blade-works-layout" ref={bladeWorksRef}>
        <BladeContext.Provider
          value={{
            register: registerBlade,
            deregister: unregisterBlade,
            blades: state!.blades
          }}
        >
          <Arsenal>{children}</Arsenal>
        </BladeContext.Provider>
      </div>
    </div>
  );
};

export default BladeWorks;
