import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  ReduxAction
} from "../actions";

export const authenticate = (
  state = {
    isAuthenticated: false,
    user: null,
    message: "",
    inProgress: false
  },
  action: ReduxAction
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, ...{ inProgress: action.payload.inProgress } };
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...{ inProgress: false, isAuthenticated: true, user: action.payload }
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        ...{
          inProgress: false,
          isAuthenticated: false,
          user: null,
          message: action.payload
        }
      };
    default:
      return state;
  }
};
