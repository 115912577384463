import { getAccessToken, createAuthManager } from "./auth";
import { ValidAccessTokenNew, ValidatedAccessToken } from "./auth/ValidToken";
import { getTenantId, delay } from "./utils";
import axios from "axios";
import { apiBase } from "./buildinfo.json";
import { oidcConfig } from "./auth";

export const setApiBase = base => (BASE_URL = base);

export let BASE_URL = apiBase;

export const getStorageUrl = (project, version = "v1") =>
  `${BASE_URL}/storage/${version}/${getTenantId()}/${project}`;

// create an axios (http lib) client with the base url
const apiClient = axios.create({
  baseURL: BASE_URL
});

apiClient.interceptors.request.use(
  config => {
    const origReq = config;
    const authManger = createAuthManager();
    if (!authManger.isTokenValid()) {
      authManger.silentRefresh();
      return delay(2000).then(() => {
        origReq.headers = {
          Authorization: `Bearer ${authManger.getAccessToken()}`
        };
        return Promise.resolve(origReq);
      });
    }
    return config;
  },
  err => Promise.reject(err)
);

export const createCancelToken = () => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  return {
    token: source.token,
    cancel: source.cancel
  };
};

export enum ApiCallMethod {
  get = "GET",
  delete = "DELETE"
}

export enum ApiPostMethod {
  post = "POST",
  put = "PUT"
}

export interface ApiCallOptions {
  method: "get" | "delete" | "post" | "put" | "patch" | "head";
  error?: any;
  success?: any;
  params?: any;
  version?: string;
  cancelToken?: any;
  asForm?: boolean;
  headers?: any;
  requestMod?: string;
  unpack?: boolean;
  useGraphToken?: boolean;
  sendInBody?: boolean;
}

export interface ApiPostOptions {
  method?: ApiPostMethod;
  error?: any;
  success?: any;
  params?: any;
  version?: string;
}

const standardApiOptions: ApiCallOptions = {
  method: "get",
  error: error => console.error(error),
  success: data => data,
  params: undefined,
  version: "v1",
  cancelToken: null,
  asForm: false,
  headers: {},
  requestMod: undefined,
  unpack: true,
  useGraphToken: false
};

const standardApiPostOptions = {
  method: ApiPostMethod.post,
  error: error => console.error(error),
  success: data => data,
  params: undefined,
  version: "v1"
};

const addTenantToRequest = (
  needsTenant: boolean,
  insertBeforeTenant?: string,
  overrideTenant: boolean = false,
  tenant?: string
) => {
  if (needsTenant) {
    let value = "";
    if (insertBeforeTenant) {
      value = insertBeforeTenant;
    }
    return overrideTenant
      ? "/" + (value ? +"/" + value + "/" + tenant : "/" + tenant)
      : value
      ? "/" + value + "/" + getTenantId()
      : "/" + getTenantId();
  } else {
    return "";
  }
};

const generateApiRequest = (
  endpoint,
  version,
  query: string | undefined = undefined,
  needsTenant: boolean = true,
  overrideTenant = false,
  tenant = "",
  overrideBase: string | undefined = undefined,
  insertBefore = ""
) => {
  return overrideBase
    ? `${overrideBase}${addTenantToRequest(
        needsTenant,
        insertBefore,
        overrideTenant,
        tenant
      )}${query ? "/" + query : ""}`
    : `${BASE_URL}/${endpoint}/${version}${addTenantToRequest(
        needsTenant,
        insertBefore,
        overrideTenant,
        tenant
      )}${query ? "/" + query : ""}`;
};

// changed to V3 API as of 2023/10/05
export const getTenantData = async (tenant: string) => {
  const accessToken = getAccessToken(false);
  const url = `${BASE_URL}/v3/tenant/${tenant}`;

  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  });

  if (response.status === 401) {
    return Promise.reject({
      status: 401,
      message: "Unauthorized"
    });
  }

  const json = await response.json();
  return json.data.tenant;
};

export const getTenantFeatures = async (tenant: string) => {
  try {
    const accessToken = getAccessToken(false);

    const requestUrl = generateApiRequest(
      "tenants",
      "v1",
      "features",
      true,
      true,
      tenant
    );

    const response = await fetch(requestUrl, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    });

    if (response.status === 401) {
      return Promise.reject({
        status: 401,
        message: "Unauthorized"
      });
    }

    const json = await response.json();
    return json;
  } catch (e) {
    return JSON.parse(localStorage.getItem(".Knowledge.Hub.Tenant.Features")!);
  }
};

export const validateToken = async (
  token: string,
  tenant: string
): Promise<ValidAccessTokenNew> => {
  const result = await fetch(`${oidcConfig.authority}/connect/userinfo`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  });

  if (result.status === 401) {
    return Promise.reject({
      status: 401,
      message: "Unauthorized"
    });
  }
  const jsonData = await result.json();

  console.log(jsonData);

  return jsonData as ValidAccessTokenNew;
};

const sendInBody = (reqConfig: ApiCallOptions) =>
  ["post", "put", "patch"].indexOf(reqConfig.method) > -1 ||
  reqConfig.sendInBody;

export const apiRequest = ({
  endpoint,
  query,
  options,
  override,
  needsTenant
}) => {
  return callApi(endpoint, query, options, override, needsTenant);
};

export const v3ApiRequest = async (
  url,
  method,
  params,
  signal,
  override = ""
) => {
  const accessToken = getAccessToken(false);
  const baseApiUrl = `${BASE_URL}/v3/${override}${getTenantId()}`;

  try {
    console.log("calling v3 api with url:", method, url);
    const headers: any = {
      Authorization: `Bearer ${accessToken}`
    };

    if (params instanceof FormData) {
      delete headers["Content-Type"];
    } else {
      headers["Content-Type"] = "application/json";
      params = JSON.stringify(params);
    }

    let result = await fetch(`${baseApiUrl}${url}`, {
      method,
      body: params,
      headers,
      signal
    });
    // const result = await apiClient({
    //     method,
    //     url: `${baseApiUrl}${url}`,
    //     [sendInBody({method}) ? "data" : "params"]: params,
    //     cancelToken,
    //     headers
    // });

    if (result.status === 500) {
      return Promise.reject({
        status: 500,
        message: "Something went wrong"
      });
    }

    if (result.status === 401) {
      return Promise.reject({
        status: 401,
        message: "Unauthorized"
      });
    }

    if (result.status === 404) {
      return Promise.reject({ status: 404, message: "Not found" });
    }

    result = await result.json();

    // @ts-ignore
    if (result.error) {
      // @ts-ignore
      return Promise.reject(result.error);
    }

    // @ts-ignore
    return Promise.resolve(result.data ? result.data : result);
  } catch (error) {
    if (axios.isCancel(error)) {
      console.warn("Request Cancelled", error.message);
    }
    return Promise.reject(error.response.data);
  }
};

export const callApi = async (
  endpoint: string,
  query: string = "",
  options: ApiCallOptions = standardApiOptions,
  override: string | undefined = undefined,
  needsTenant: boolean = true
) => {
  const requestOptions = { ...standardApiOptions, ...options };
  const requestUrl = generateApiRequest(
    endpoint,
    requestOptions.version,
    query,
    needsTenant,
    false,
    "",
    override,
    requestOptions.requestMod
  );

  const accessToken = getAccessToken(requestOptions.useGraphToken);

  try {
    console.log("calling api with url:", requestOptions.method, requestUrl);

    const headers: any = {
      Authorization: `Bearer ${accessToken}`,
      ...requestOptions.headers
    };

    if (requestOptions.asForm) {
      headers["Content-Type"] = "multipart/form-data";
    }

    ///@ts-ignore
    const result = await apiClient({
      method: requestOptions.method,
      url: requestUrl,
      [sendInBody(requestOptions) ? "data" : "params"]: requestOptions.params,
      cancelToken: requestOptions.cancelToken,
      headers
    });

    if (result.status === 500) {
      return Promise.reject({
        status: 500,
        message: "Something went wrong"
      });
    }

    if (result.status === 401) {
      return Promise.reject({
        status: 401,
        message: "Unauthorized"
      });
    }

    if (requestOptions.unpack) {
      return Promise.resolve(result.data);
    } else {
      return Promise.resolve(result);
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.warn("Request Cancelled", error.message);
    }
    return Promise.reject(error.response.data);
  }
};

export const postApi = async (
  endpoint: string,
  query: string | undefined,
  options: ApiPostOptions = standardApiPostOptions
) => {
  const apiOptions = { ...standardApiOptions, ...options };
  const requestUrl = generateApiRequest(endpoint, apiOptions.version, query);
  const accessToken = getAccessToken(apiOptions.useGraphToken);
  try {
    let result = await fetch(requestUrl, {
      method: apiOptions.method,
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${accessToken}`
      },
      body: apiOptions.params ? JSON.stringify(apiOptions.params) : null,
      mode: "cors"
    });

    if (result.status === 401) {
      return Promise.reject({
        status: 401,
        message: "Unauthorized"
      });
    }

    const data = await result.json();
    const operationResult = apiOptions.success
      ? apiOptions.success(data)
      : data;
    return Promise.resolve(operationResult);
  } catch (error) {
    return Promise.reject(error);
  }
};
