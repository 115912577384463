import * as React from "react";
import AccessProtector from "./features/shared/AccessProtector";
import { setApiBase } from "./api";

class App extends React.Component<{}, {}> {
  componentDidMount() {
    fetch("/buildinfo.json")
      .then(res => res.json())
      .then(res => setApiBase(res.apiBase));
  }

  render() {
    return (
      <AccessProtector>
        /// @ts-ignore
        {this.props.children}
      </AccessProtector>
    );
  }
}

export default App;
