import { urlUtility } from "../utils";
import { createAuthenticationRequest, parseJwt } from ".";
import { validateToken } from "../api";
import { ValidToken } from "./ValidToken";
import { oidcConfig } from ".";

class AuthManager {
  store = localStorage;
  prefix = "khub.auth";
  keyTypes = {
    request: ".request",
    user: ".user"
  };

  silentRefreshInterval: any = null;

  constructor() {
    window.addEventListener("message", this.receiveMessage, false);
  }

  receiveMessage = (evt: MessageEvent) => {
    if (
      evt.origin !== window.location.origin ||
      typeof evt.data !== "string" ||
      evt.data === "" ||
      evt.data.indexOf("setImmediate") === 0
    ) {
      return;
    }

    console.log(evt);

    this.handleAuthentication(evt.data);
  };

  getUser(): ValidToken | null {
    const userData = localStorage.getItem(this.getStoreKey("user"));
    if (userData === null) {
      return null;
    }
    return JSON.parse(userData);
  }

  clearAuthStore() {
    for (let i = 0; i < this.store.length; i++) {
      if (this.store.key(i)!.indexOf("khub.auth") === 0) {
        this.store.removeItem(this.store.key(i)!);
      }
    }
  }

  // checks if the token that is currently saved for the user is still valid.
  isTokenValid(): boolean {
    const user = this.getUser();
    if (user === null) {
      return false;
    }
    const expTime = parseInt(user.claims.exp);
    const currentTime = new Date().getTime() / 1000;
    return currentTime < expTime;
  }

  getAccessToken = () => {
    const user: ValidToken | null = this.getUser();
    if (user === null) {
      return "";
    }
    return user.access_token;
  };

  signOut() {
    const idtoken = this.getUser()!.id_token;
    this.clearAuthStore();
    window.location.href = `${
      oidcConfig.authority
    }/connect/endsession?id_token_hint=${idtoken}&post_logout_redirect_uri=${
      oidcConfig.post_logout_redirect_uri
    }`;
    return undefined;
  }

  startSignIn(options?: Array<{ key; value }>): any {
    const url = createAuthenticationRequest(oidcConfig.redirect_uri, options);
    window.location.href = url;
  }

  async handleAuthentication(hash: string) {
    var values: any = urlUtility.parseUrlHash(hash);

    if (values.error) {
      window.dispatchEvent(new CustomEvent("loginExpired"));
      return null;
    }

    const isValidSignIn = await this.isValidSignState(values);
    if (!isValidSignIn) {
      throw new Error("Not a valid Sign In!");
    }

    return this.getUser();
  }

  isLoggedIn() {
    const user = this.getUser();
    if (user === null) {
      return false;
    }
    const expTime = parseInt(user.claims.exp);
    const currentTime = new Date().getTime() / 1000;

    return currentTime > expTime;
  }

  authenticate(options?: Array<{ key; value }>): Promise<ValidToken | null> {
    if (window.location.hash) {
      return this.handleAuthentication(window.location.hash);
    } else {
      this.startSignIn(options);
      return Promise.resolve(null);
    }
  }

  silentSignIn = () => {
    console.log(this.getUser()!.state);
    const expTime = parseInt(this.getUser()!.claims.exp);
    const currentTime = new Date().getTime() / 1000;
    if (currentTime > expTime) {
      this.clearAuthStore();
      return Promise.reject(false);
    }
    return this.isValidSignState(this.getUser()!);
  };

  clearStates() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== null && key.indexOf("khub.auth.request") === 0) {
        this.store.removeItem(key);
      }
    }
  }

  silentRefresh = () => {
    console.log("refreshing access silently");

    let userData: string | ValidToken | null = this.store.getItem(
      this.getStoreKey("user")
    );
    if (userData === null) {
      window.clearInterval(this.silentRefreshInterval);
      this.silentRefreshInterval = null;
      return;
    }
    userData = JSON.parse(userData) as ValidToken;

    this.store.removeItem(this.getStoreKey("request", userData.state));

    /// @ts-ignore
    document
      .getElementById("silentRefresh")
      .setAttribute(
        "src",
        createAuthenticationRequest(oidcConfig.redirect_uri_silent) +
          "&prompt=none"
      );
  };

  private getStoredSignIn(state: string) {
    const storedState = this.store.getItem(this.getStoreKey("request", state));

    return storedState !== null ? JSON.parse(storedState) : null;
  }

  private getStoreKey(type: string, id?: string) {
    return `${this.prefix}${this.keyTypes[type]}${id ? ":" + id : ""}`;
  }

  private async isValidSignState(signInState: any): Promise<boolean> {
    if (!signInState.state) {
      return Promise.reject(new Error("Not a valid Sign In! Error: no state"));
    }

    const signIn = this.getStoredSignIn(signInState.state);

    if (signIn === null) {
      return Promise.reject(
        new Error("Not a valid Sign In! Error: no stored SignIn")
      );
    }

    try {
      const claims = parseJwt(signInState.access_token);
      const validatedToken = await validateToken(
        signInState.access_token,
        claims.tenant
      );

      if (!validatedToken.user) {
        throw new Error("Unauthorized");
      }

      document.cookie = `.Knowledge.Hub.Access=${
        signInState.access_token
      };path=/;domain=knowledgehub.cloud;max-age=3600;secure`;

      this.store.setItem(
        this.getStoreKey("user"),
        JSON.stringify({
          ...signInState,
          claims
        })
      );

      const refreshTimeInMs = (parseInt(signInState.expires_in) - 10) * 1000;
      console.log(`Refreshing every ${refreshTimeInMs} ms`);
      if (this.silentRefreshInterval !== null) {
        window.clearInterval(this.silentRefreshInterval);
      }
      this.silentRefreshInterval = window.setInterval(
        this.silentRefresh,
        refreshTimeInMs
      );
      return Promise.resolve(true);
    } catch (e) {
      console.error(e);
      return Promise.reject(new Error("Not a valid Sign In!" + e.message));
    }
  }
}

let currentAuthManager: AuthManager | null = null;
export const createAuthManager = () => {
  if (currentAuthManager == null) {
    currentAuthManager = new AuthManager();
  }
  return currentAuthManager;
};
