export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";

export interface ReduxAction {
  type: string;
  payload: any;
}

export const requestLogin = (): ReduxAction => ({
  type: LOGIN_REQUEST,
  payload: {
    inProgress: true
  }
});

export const receiveUser = (user): ReduxAction => ({
  type: LOGIN_SUCCESS,
  payload: user
});

export const loginError = (message): ReduxAction => ({
  type: LOGIN_FAILURE,
  payload: message
});
