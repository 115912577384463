import * as React from "react";
import { NavLink as DomNavLink } from "react-router-dom";

export const NavLink = ({ expanded, ...props }) => {
  return (
    //@ts-ignore
    <DomNavLink {...props} className="nav-link" activeClassName="nav-active">
      {props.icon && (
        <img title={props.children} className="nav-icon" src={props.icon} />
      )}{" "}
      {expanded !== false && <span>{props.children}</span>}
    </DomNavLink>
  );
};
