import * as React from "react";
import { Icon } from "antd";
import { DeepLink } from "../features/shared/DeepLink";
import { AuthContext, isPermitted } from "../auth";
import { useBladeContext } from "./BladeWorks";
import v4 from "uuid/v4";

interface BladeProps {
  size?: "small" | "medium" | "large" | "xlarge";
  title?: string;
  subtitle?: string;
  isPlaceholder?: boolean;
  children?: any;
  returnPath?: string;
  buttons?: any;
  index?: number;
  isLastBlade?: boolean;
  noPadding?: boolean;
  returnFn?: () => void;
  style?: any;
}

function Blade({
  size,
  title,
  subtitle,
  isPlaceholder,
  returnPath,
  returnFn,
  style,
  ...props
}: BladeProps) {
  const bladeRef = React.useRef(v4());

  const context = useBladeContext();

  React.useLayoutEffect(() => {
    const elem = document.querySelector(".blade-works-layout")!;
    elem.scrollLeft = elem.scrollWidth;
  }, []);

  React.useEffect(
    () => {
      if (!isPlaceholder && size !== undefined) {
        context.register({ id: bladeRef.current, size });
      }
      return () => {
        if (!isPlaceholder && size !== undefined) {
          context.deregister({ id: bladeRef.current, size });
        }
      };
    },
    [bladeRef, size, isPlaceholder]
  );

  if (isPlaceholder) {
    return (
      <div
        className={`blade blade-placeholder blade-placeholder-${size}`}
        style={style}
      >
        <span
          style={{
            fontFamily: "khub",
            fontSize: "192px",
            lineHeight: "192px",
            position: "absolute",
            bottom: "15px",
            right: "25px",
            color: "rgba(0, 0, 0, .1)",
            pointerEvents: "none"
          }}
        >
          {String.fromCharCode(parseInt("e835", 16))}
        </span>
      </div>
    );
  }

  // if (!returnFn && !returnPath) {
  //   console.log(returnFn, returnPath);
  //   throw new Error("Something to return to or execute is required!");
  // }

  const bladeClasses = `blade blade-size-${size}`;
  return (
    <>
      <section className={bladeClasses}>
        <header className="blade-header">
          {returnPath && (
            <DeepLink to={returnPath} title="Close without saving">
              <i className="blade-close material-icons">close</i>
            </DeepLink>
          )}
          {returnFn && (
            <i className="blade-close material-icons" onClick={returnFn}>
              close
            </i>
          )}
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </header>
        {props.buttons && (
          <div className="blade-buttons">
            {props.buttons ? props.buttons() : null}
          </div>
        )}
        <div className="blade-content-container">
          <div
            className="blade-content"
            style={props.noPadding ? { padding: "0" } : {}}
          >
            {props.children}
          </div>
        </div>
      </section>
      {props.isLastBlade && (
        <Blade
          isPlaceholder
          size={size}
          returnPath={returnPath}
          style={style}
        />
      )}
    </>
  );
}

export const BladePlaceholder = ({ size }: { size: number }) => (
  <Blade
    isLastBlade
    style={{
      flexBasis: `calc(100% - ${size}px`,
      minWidth: `calc(100% - ${size}px`
    }}
    returnPath=""
  />
);

type BladeContentAlignment = "order" | "stack";

export const BladeContentContainer = ({
  align,
  ...props
}: {
  align: BladeContentAlignment;
  children?: any;
}) => (
  <div
    className={`blade-custom-content-container  blade-${align}-container`}
    {...props}
  />
);

export const BladeContent = ({
  withMenu,
  ...props
}: {
  withMenu?: boolean;
  children?: any;
  style?: any;
}) => (
  <div
    className={`blade-custom-content${withMenu ? "-menu" : ""}`}
    {...props}
  />
);

export const BladeButton = ({
  onClick,
  text,
  icon,
  isActive,
  permissions,
  requireAny = false
}: {
  onClick: any;
  text?: string;
  icon?: any;
  isActive?: boolean;
  permissions?: string[];
  requireAny?: boolean;
}) => (
  <AuthContext.Consumer>
    {({ user }) => {
      if (!isPermitted(permissions, user!.claims.permissions, !requireAny)) {
        return null;
      }

      return (
        <div
          className={"blade-button" + (isActive ? " blade-button-active" : "")}
          onClick={onClick}
        >
          {icon && <Icon type={icon} />}
          <span>{text}</span>
        </div>
      );
    }}
  </AuthContext.Consumer>
);

export const BladeButtonLink = ({
  text,
  icon,
  to,
  external,
  isActive,
  permissions,
  requireAny = false
}: {
  text: string;
  icon: string;
  to: string;
  external?: boolean;
  isActive?: boolean;
  permissions?: string[];
  requireAny?: boolean;
}) => (
  <AuthContext.Consumer>
    {({ user }) => {
      if (!isPermitted(permissions, user!.claims.permissions, !requireAny)) {
        return null;
      }

      return (
        <DeepLink to={to} external={external}>
          <div
            className={
              "blade-button" + (isActive ? " blade-button-active" : "")
            }
          >
            <Icon type={icon} />
            <span>{text}</span>
          </div>
        </DeepLink>
      );
    }}
  </AuthContext.Consumer>
);

export default Blade;
