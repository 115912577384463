import * as React from "react";
import { Splash } from "./Splash";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { createAuthManager } from "../../auth";
import { connect } from "react-redux";
import { receiveUser, loginError } from "../../actions";

const authManager = createAuthManager();

interface LoginCallbackProps extends RouteComponentProps<any> {
  successCallback: (user: any) => void;
  history: any;
}

interface SignInComponentProps extends LoginCallbackProps {
  userSignedIn: (user: any) => void;
  loginFailed: (message: string) => void;
}

class SignInComponent extends React.Component<SignInComponentProps> {
  componentDidMount() {
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);

      const tenant = searchParams.get("tenant");

      if (tenant !== null) {
        authManager
          .authenticate([{ key: "acr_values", value: `tenant:${tenant}` }])
          .then(this.successCallback)
          .catch(this.errorCallback);

        return;
      }
    }

    authManager
      .authenticate()
      .then(this.successCallback)
      .catch(this.errorCallback);
  }

  successCallback = () => {
    const user = authManager.getUser();
    if (user === null) {
      console.log("DA IS WAS FALSCH!!");
    }
    this.props.userSignedIn(user);
    this.props.successCallback(user);
  };

  errorCallback = () => {
    this.props.loginFailed("Login Failed");
  };

  render() {
    return React.Children.only(this.props.children);
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  userSignedIn: user => dispatch(receiveUser(user)),
  loginFailed: message => dispatch(loginError(message))
});

const ConnectedSignInComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInComponent);

const LoginCallback = (props: LoginCallbackProps) => (
  <ConnectedSignInComponent {...props}>
    <Splash />
  </ConnectedSignInComponent>
);

export default withRouter(LoginCallback);
