import * as React from "react";
import { ValidToken } from "./ValidToken";

interface AuthContextData {
  isAuthenticated: boolean;
  user: ValidToken | null;
}

const isAuthenticated = (): boolean => {
  // const currentNetwork = getCurrentNetwork();
  // if (currentNetwork === null) {
  //     return false;
  // }

  // let authData = localStorage.getItem("hello");
  // if (authData === null) {
  //     return false;
  // }
  // authData = JSON.parse(authData);

  // const loginData = authData![currentNetwork];

  // if(!loginData) {
  //     return false;
  // }

  // const currentTime = new Date().getTime() / 1000;

  // return loginData.access_token && loginData.expires > currentTime;
  return false;
};

const createAuthContext = () => {
  const isLoggedIn = isAuthenticated();

  const authData: AuthContextData = {
    isAuthenticated: isLoggedIn,
    user: null
  };
  return React.createContext(authData);
};

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error("Auth Context usage only inside a provider!");
  }

  return context;
};

export const AuthContext = createAuthContext();
