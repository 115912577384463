import * as React from "react";

export const createFeatureConfigFromTenant = () => {
  var availableFeatures = JSON.parse(
    localStorage.getItem(".Knowledge.Hub.Tenant.Features")!
  );

  availableFeatures = availableFeatures.map(f =>
    f.feature === "thinx"
      ? { ...f, path: "/elements", feature: "elements", name: "Elements" }
      : f
  );

  return availableFeatures.map(f => ({
    ...f,
    Component: React.lazy(() => import(`./${f.feature}`))
  }));
};

export const isFeatureEnabled = feature => {
  var availableFeatures = JSON.parse(
    localStorage.getItem(".Knowledge.Hub.Tenant.Features")!
  );

  return availableFeatures.filter(f => f.feature === feature).length > 0;
};

export const FEATURES = Object.freeze({
  editor: "contenteditor",
  userManagement: "users",
  projectManagement: "projects"
});
