import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../../auth";

export const ProtectedRoute = ({
  path,
  component,
  render,
  exact = false,
  ...props
}: {
  path: string;
  component?: any;
  render?: any;
  exact?: boolean;
}) => (
  <AuthContext.Consumer>
    {auth => (
      <Route
        path={path}
        exact={exact}
        render={rProps => {
          return auth.isAuthenticated ? (
            component ? (
              React.createElement(component, rProps)
            ) : (
              render(rProps)
            )
          ) : (
            <Redirect to="/" />
          );
        }}
      />
    )}
  </AuthContext.Consumer>
);
