import * as React from "react";
import logo from "../../images/skh_logo_anim.gif";

export const Splash = (props: { children?: any }) => {
  return (
    <div className="splashscreen">
      <div className="logoSplash">
        <img src={logo} />
      </div>
      {props.children}
    </div>
  );
};
