import { createStore, compose } from "redux";
import rootReducer from "./reducers";

///@ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState = {}) => {
  const store = createStore(
    /// @ts-ignore
    rootReducer,
    initialState,
    /// @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  /// @ts-ignore
  if (module.hot) {
    /// @ts-ignore
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers").default;
      ///@ts-ignore
      store.replaceReducer(connectRouter(history)(nextRootReducer));
    });
  }
  /// @ts-ignore
  window.store = store;
  return store;
};

export default configureStore;
