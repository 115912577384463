import * as React from "react";
import { useAuthContext } from "./auth/AuthContext";
import { StandardReport } from "./features/reporting/StandardReport";

export const Dashboard = props => {
  const { user } = useAuthContext();

  if (
    !user!.claims.permissions ||
    user!.claims.permissions.length == 0 ||
    props.denied
  ) {
    return (
      <h1 style={{ padding: 15 }}>
        It seems like you do not have permissions to manage Smart Knowledge Hub.
        Please contact your Smart Knowledge Hub administrator if you need those
        permissions!
      </h1>
    );
  }

  return (
    <div
      style={{
        marginLeft: 15,
        height: "calc(100vh - 71px)",
        overflowY: "auto"
      }}
    >
      <StandardReport />
    </div>
  );
};
