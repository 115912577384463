import { validateToken } from "../api";
import { addQueryParameter } from "../utils/urlUtilities";
import random from "./random";
import { createAuthManager } from "./AuthManager";
import { getCookie } from "../utils";
export { createAuthManager } from "./AuthManager";
import { authServer } from "../buildinfo.json";
import { intersection } from "lodash";

export const TokenTypes = {
  tenant: "tenant",
  user: "userid",
  name: "name"
};

export const Permissions = {
  ManageProjects: "project_management",
  ManageUsers: "user_management",
  ManagePermissions: "permission_management",
  PublishProject: "publishing",
  ManageGroups: "group_management",
  ThinxElements: "thinx_elements",
  CertificateTemplates: "certtemplates",
  Playlists: "playlists",
  ManageIntegrations: "integration_management",
  TagManagement: "tag_management"
};

export const isPermitted = (required, actual, requireAll = true) => {
  if (!actual) return false;

  if (!required || !required.length) {
    return true;
  }

  if (!requireAll) {
    return required.some(p => actual.includes(p));
  }

  return intersection(required, actual).length === required.length;
};

export { AuthContext } from "./AuthContext";

export const getTokenTypes = () => TokenTypes;

export const getAccessToken = useGraphToken =>
  useGraphToken
    ? getCookie(".Knowledge.Hub.Graph")
    : createAuthManager().getUser()!.access_token;

export const getAuthData = async userData => {
  try {
    let token = await validateToken(
      userData.access_token,
      userData.profile.tenant
    );
    token = JSON.parse(token.toString());
    document.cookie = `.Knowledge.Hub.Tenant=${userData.profile.tenant}`;
    return Promise.resolve(token);
  } catch (e) {
    console.log(e);
    return Promise.reject({
      status: 401,
      message: "Unauthorized"
    });
  }
};

export const oidcConfig = {
  client_id: "Knowledge.Hub.Portal",
  authority: authServer,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }/login`,
  redirect_uri_silent: `${window.location.protocol}//${
    window.location.hostname
  }${
    window.location.port ? `:${window.location.port}` : ""
  }/signin_silent.html`,
  response_type: "token id_token",
  scope: "openid portal api knowledge.hub.profile",
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}`
};

/// @ts-ignore
window.oidcConfig = oidcConfig;

export const createAuthenticationRequest = (
  redirectUri,
  otherOptions?: Array<{ key; value }>
) => {
  let url = `${oidcConfig.authority}/connect/authorize/callback`;

  url = addQueryParameter(url, "client_id", oidcConfig.client_id);
  url = addQueryParameter(url, "redirect_uri", redirectUri);
  url = addQueryParameter(url, "response_type", oidcConfig.response_type);
  url = addQueryParameter(url, "scope", oidcConfig.scope);

  if (otherOptions) {
    for (const { key, value } of otherOptions) {
      url = addQueryParameter(url, key, value);
    }
  }

  const requestState = {
    clientid: oidcConfig.client_id,
    state: random(),
    nonce: random(),
    creation: parseInt(String(Date.now() / 1000))
  };

  localStorage.setItem(
    `khub.auth.request:${requestState.state}`,
    JSON.stringify(requestState)
  );

  url = addQueryParameter(url, "state", requestState.state);
  url = addQueryParameter(url, "nonce", requestState.nonce);

  return url;
};

const b64DecodeUnicode = str =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );

export const parseJwt = token =>
  JSON.parse(
    b64DecodeUnicode(
      token
        .split(".")[1]
        .replace("-", "+")
        .replace("_", "/")
    )
  );
