import * as React from "react";
import { Icon } from "antd";

interface GIconProps {
  icon: string;
  classes?: string[];
  outline?: boolean;
  style?: any;
}

export const AIcon = ({ icon, classes = [] }: GIconProps) => (
  <Icon className={classes.join(" ")} type={icon} />
);

export const GIcon = ({
  icon,
  classes = [],
  outline = false,
  style = undefined
}: GIconProps) => (
  <i
    className={`${["material-icons"].concat(classes).join(" ")}`}
    style={style}
  >
    {`${icon}${outline ? "_outline" : ""}`}
  </i>
);
