import { callApi, postApi, ApiPostMethod, ApiPostOptions } from "../../api";
import { User } from "./Models/User";

export const getUserList = async () => {
  return await callApi("users");
};

export const getGroupsList = async () => {
  return await callApi("users", "groups");
};

export const getUserListAzure = async organisation => {
  try {
    const jsonData = await callApi(
      "graph",
      `${organisation}/users`,
      {
        method: "get",
        useGraphToken: true
      },
      undefined,
      false
    );
    return jsonData.value;
  } catch (error) {
    return [];
  }
};

export const getUser = async id => {
  return await callApi("users", "user/" + id);
};

export const getUserAzure = async (id, organisation) => {
  const jsonData = await callApi(
    "graph",
    `${organisation}/users/${id}`,
    {
      method: "get",
      useGraphToken: true
    },
    undefined,
    false
  );
  return jsonData as User;
};

export const updateUser = async user => {
  return await callApi("users", "user/" + user.userid, {
    method: "put",
    params: { ...user, ssoproperties: JSON.stringify(user.ssoproperties) }
  });
};

export const createUser = async user => {
  let postCallOptions: ApiPostOptions = {
    method: ApiPostMethod.post,
    error: undefined,
    success: undefined,
    params: user
  };

  return await postApi("/users", undefined, postCallOptions);
};

export const deleteUser = async id => {
  return await callApi("/users", id, { method: "delete" });
};

export const setGroupRelations = async (
  group,
  objects,
  objectType = "user"
) => {
  const data = objects.map(obj => ({
    groupId: group,
    objectId: obj,
    objectType
  }));

  return await callApi("/users", `groups/${group}/relations`, {
    method: "post",
    params: data
  });
};

export const removeGroupRelations = async (
  group,
  objects,
  objectType = "user"
) => {
  const data = objects.map(obj => ({
    groupId: group,
    objectId: obj,
    objectType
  }));

  return await callApi("/users", `groups/${group}/relations`, {
    method: "delete",
    params: data,
    sendInBody: true
  });
};

export const saveGroupInfo = async (
  group,
  name,
  isReportGroup,
  isPermissionGroup,
  isOpenGroup
) => {
  return await callApi("/users", `groups/${group}`, {
    method: "put",
    params: {
      groupName: name,
      isReportGroup,
      isPermissionGroup,
      isOpenGroup
    }
  });
};

export const createGroup = async groupName => {
  return await callApi("/users", "groups", {
    method: "post",
    params: {
      groupName
    }
  });
};

export const deleteGroup = async id =>
  await callApi("/users", `groups/${id}`, { method: "delete" });

export const addUsersToRoles = async (users, role) => {
  return await callApi("users", "roles", {
    method: "post",
    params: users.map(user => ({ userid: user, roleid: role }))
  });
};

export const addPermissionToUsers = async (user, permission) => {
  return await callApi("users", "permissions", {
    method: "post",
    params: permission.map(permission => ({
      userid: user,
      permissionid: permission
    }))
  });
};

export const removePermissionFromUser = async (user, permission) => {
  return await callApi("users", "permissions", {
    method: "delete",
    params: [{ userid: user, permissionid: permission }],
    sendInBody: true
  });
};
