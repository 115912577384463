import * as React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

const Login = ({ doLogin }) => {
  const [language, setLanguage] = React.useState("en");

  React.useEffect(() => {
    var lang = navigator.language.substr(0, 2);
    setLanguage(lang);
  }, []);

  const t = identifier => {
    /* 
            hardcoded strings for minimal login stuff. 
            for now only DE/EN since singIn goes to auth server which also uses
            available: ["de","en"],
        */
    const strings = {
      Login_Welcome: {
        de: "Willkommen im Wissensportal",
        en: "Welcome to KnowledgeHub"
      },
      Login_Signin: {
        de: "Weiter zur Anmeldung",
        en: "Continue to login"
      }
    };
    return strings[identifier][language];
  };

  return (
    <div className="page">
      <header>
        <h1>{t("Login_Welcome")}</h1>
      </header>

      <div className="loginContainer">
        <Link to="/login">
          <button>{t("Login_Signin")}</button>
        </Link>
      </div>

      <footer>
        <svg
          width="48"
          height="48"
          version="1.1"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="m12.7 27.525 34.169-20.177 34.169 20.177v39.57l-34.169 20.177-34.169-20.177z"
              fill="#fff"
              stroke="#000"
              stroke-width=".26758px"
            />
            <g
              transform="matrix(.41358 0 0 -.41358 -246.03 92.878)"
              fill="#48494c"
            >
              <path d="m790.82 64.474-82.444-47.589-82.792 47.589v93.544l82.792 47.699 82.444-47.699zm-82.618 154.1-93.75-54.127v-106.4l93.924-54.127 93.576 54.127v106.4l-93.576 54.127" />
              <path d="m708.18 123.44c5.9238 0 10.743-4.8182 10.743-10.741 0-5.9238-4.819-10.743-10.743-10.743-5.923 0-10.742 4.819-10.742 10.743 0 5.923 4.819 10.741 10.742 10.741zm0-27.955c9.4915 0 17.213 7.7218 17.213 17.213 0 9.4907-7.7218 17.212-17.213 17.212-9.4907 0-17.213-7.721-17.213-17.212 0-9.4915 7.7218-17.213 17.213-17.213" />
              <circle
                transform="scale(1,-1)"
                cx="665.06"
                cy="-71.02"
                r="13.313"
                stroke-width="0"
              />
              <circle
                transform="scale(1,-1)"
                cx="708.18"
                cy="-68.02"
                r="13.313"
                stroke-width="0"
              />
              <circle
                transform="scale(1,-1)"
                cx="751.31"
                cy="-154.37"
                r="13.313"
                stroke-width="0"
              />
              <circle
                transform="scale(1,-1)"
                cx="679.82"
                cy="-140.79"
                r="10.129"
                stroke-width="0"
              />
              <circle
                transform="scale(1,-1)"
                cx="737.49"
                cy="-84.28"
                r="10.129"
                stroke-width="0"
              />
              <rect
                transform="matrix(0,-1,-1,0,0,0)"
                x="-100.1"
                y="-711.81"
                width="33.659"
                height="7.259"
                stroke-width="0"
              />
              <g stroke-width="0">
                <rect
                  transform="matrix(-.70711 -.70711 -.70711 .70711 0 0)"
                  x="-569.23"
                  y="-424.19"
                  width="41.762"
                  height="7.259"
                />
                <rect
                  transform="matrix(-.70711 -.70711 -.70711 .70711 0 0)"
                  x="-633.43"
                  y="-425.22"
                  width="41.762"
                  height="7.259"
                />
              </g>
              <rect
                transform="matrix(-.71975 .69424 .69748 .7166 0 0)"
                x="-415.58"
                y="569.79"
                width="36.158"
                height="7.2588"
                stroke-width="0"
              />
              <rect
                transform="matrix(-.71975 .69424 .69748 .7166 0 0)"
                x="-479.26"
                y="568.91"
                width="36.158"
                height="7.2588"
                stroke-width="0"
              />
            </g>
          </g>
        </svg>

        <a
          className="footer"
          href="https://smartknowledgehub.net/"
          target="_blank"
        >
          Smart Knowledge Hub
        </a>
      </footer>
    </div>
  );
};

export default Login;
