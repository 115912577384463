import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import configureStore from "./store";
import { BrowserRouter as Router } from "react-router-dom";

const store = configureStore();

const renderApp = Portal => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App>
          <Portal />
        </App>
      </Router>
    </Provider>,
    document.getElementById("root") as HTMLElement
  );
};

const Portal = require("./features/shared/Portal").default;

renderApp(Portal);

function UnCryptMailto(s: string) {
  var n = 0;
  var r = "";
  for (var i = 0; i < s.length; i++) {
    n = s.charCodeAt(i);
    if (n >= 8364) {
      n = 128;
    }
    r += String.fromCharCode(n - 1);
  }
  return r;
}

/// @ts-ignore
window.linkTo_UnCryptMailto = function(s: string) {
  location.href = UnCryptMailto(s);
};

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
  renderApp(require("./features/shared/Portal").default);
}
