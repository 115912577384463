import * as React from "react";
import { TenantContext } from "./TenantContext";
import { getTenantData } from "../../utils";
import { PortalContent } from "./PortalContent";
import { Nav } from "./Nav";
import { TopBar } from "./TopBar";
import { buildNumber } from "../../buildinfo.json";

export const Portal = () => {
  const [expanded, setExpanded] = React.useState(() =>
    localStorage.getItem("nav:expanded") == null
      ? true
      : localStorage.getItem("nav:expanded") == "true"
  );

  const expandNav = val => {
    setExpanded(val);
    localStorage.setItem("nav:expanded", String(val));
  };

  return (
    <TenantContext.Provider value={getTenantData()}>
      <div className={expanded ? "grid-container" : "grid-container-minimized"}>
        <TopBar />
        <Nav expanded={expanded} setExpanded={expandNav} />
        <PortalContent />
        <div className="buildInfo">Build Number: {buildNumber}</div>
      </div>
    </TenantContext.Provider>
  );
};

export default Portal;
