export const addQueryParameter = (
  url: string,
  param: string,
  value: string
) => {
  if (url.indexOf("?") < 0) {
    url += "?";
  }

  if (url[url.length - 1] !== "?") {
    url += "&";
  }

  url += encodeURIComponent(param);
  url += "=";
  url += encodeURIComponent(value);
  return url;
};

export const parseUrlHash = (value: string) => {
  if (value.indexOf("#") !== 0) {
    throw new Error("value is not a hash!");
  }
  var hashedValue = decodeURIComponent(value.substring(1));
  return hashedValue.split("&").reduce((acc, elem) => {
    const values = elem.split("=");
    return { ...acc, [values[0]]: values[1] };
  }, {});
};
