import * as React from "react";
import Blade from "../../Blades/Blade";
import { ApiCall } from "../shared/ApiCall";
import { callApi, v3ApiRequest } from "../../api";
import Debug from "../shared/DebugData";
import { AuthContext } from "../../auth";
import {
  Tabs,
  Card,
  Table,
  List,
  Tag,
  Select,
  Alert,
  Button,
  message
} from "antd";
const { TabPane } = Tabs;
const { Column } = Table;
const { Option } = Select;
import { getElements } from "../elements/thinxApi";
import { getProjectList } from "../projects/projectApi";
import { getPlaylists } from "../playlists/playlistApi";
import { getUserList } from "../users/userApi";
import { getGroupsList } from "../users/userApi";
import { getCustomReports, getCustomReport } from "./reportApi";
import "./reporting.css";
import * as Papa from "papaparse";
//@ts-ignore
import { Doughnut, Bar, Line } from "react-chartjs-2";

export const StandardReport = props => {
  const [elements, setElements] = React.useState<any>(null);
  const [projects, setProjects] = React.useState<any>(null);
  const [playlists, setPlaylists] = React.useState<any>(null);
  const [users, setUsers] = React.useState<any>(null);
  const [groups, setGroups] = React.useState<any>(null);
  const [selectedObject, setSelectedObject] = React.useState<any>(null);
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const [selectedGroup, setSelectedGroup] = React.useState<any>(null);
  const [selectedObject5, setSelectedObject5] = React.useState<any>(null);
  const [selectedUser5, setSelectedUser5] = React.useState<any>(null);
  const [selectedGroup5, setSelectedGroup5] = React.useState<any>(null);
  const [objects, setObjects] = React.useState<any>(null);
  const [publicObjects, setPublicObjects] = React.useState<any>(null);
  const [customReports, setCustomReports] = React.useState<any>(null);
  const [selectedCustomReport, setSelectedCustomReport] = React.useState<any>(
    null
  );
  const [numDays, setNumDays] = React.useState<Number>(10);
  const [
    publicContentDetailsObject,
    setPublicContentDetailsObject
  ] = React.useState<any>(null);

  React.useEffect(() => {
    if (users === null) {
      getUserList().then(a => setUsers(a.data));
    }
    if (projects === null) {
      getProjectList().then(a => setProjects(a.data));
    }
    if (elements === null) {
      getElements().then(a => setElements(a.data));
    }
    if (playlists === null) {
      getPlaylists().then(a => setPlaylists(a.data));
    }
    if (groups === null) {
      getGroupsList().then(a => setGroups(a.data.filter(g => g.isReportGroup)));
    }
    if (customReports === null) {
      getCustomReports().then(a => setCustomReports(a.data));
    }
  }, []);

  const getCallUrl = showAll => {
    if (!selectedObject && !selectedUser) return null;
    const filter: any = [];
    if (selectedObject) {
      filter.push(`filterByObject=${selectedObject}`);
    }
    if (selectedUser) {
      filter.push(`filterByUser=${selectedUser}`);
    }
    if (selectedGroup) {
      filter.push(`filterByGroup=${selectedGroup}`);
    }
    if (showAll === true) {
      filter.push(`showAll=true`);
    }
    return `standard/2?${filter.join("&")}`;
  };

  const getCallUrl5 = () => {
    if (!selectedObject5 && !selectedUser5) return null;
    const filter: any = [];
    if (selectedObject5) {
      filter.push(`filterByObject=${selectedObject5}`);
    }
    if (selectedUser5) {
      filter.push(`filterByUser=${selectedUser5}`);
    }
    if (selectedGroup5) {
      filter.push(`filterByGroup=${selectedGroup5}`);
    }
    return `standard/5?${filter.join("&")}`;
  };

  const cleanupName = name => {
    const ret = String(name).replace(/[/\\?%*:|"<>]/g, "-");
    return ret.toLocaleLowerCase();
  };

  const getDownloadFilename = showAll => {
    const parts: any = [];
    if (selectedObject) {
      parts.push(
        `content_${cleanupName(
          objects.filter(o => o.objectId === selectedObject)[0].name
        )}`
      );
    }
    if (selectedUser) {
      parts.push(
        `user_${cleanupName(
          users.filter(o => o.userid === selectedUser)[0].email
        )}`
      );
    }
    if (selectedGroup) {
      parts.push(
        `group_${cleanupName(
          groups.filter(o => o.groupId === selectedGroup)[0].groupName
        )}`
      );
    }
    if (showAll) {
      parts.push(`including_not_attempted`);
    }
    return `report_${parts.join("_")}.csv`;
  };

  if (
    elements !== null &&
    projects !== null &&
    playlists !== null &&
    objects === null &&
    publicObjects === null &&
    customReports !== null
  ) {
    const a: any = [
      ...elements.map(e => ({
        objectId: e.elementId,
        name: e.displayName,
        version: 0
      })),
      ...projects.map(e => ({
        objectId: e.projectId,
        name: e.title,
        version: e.version
      }))
    ].sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    setObjects(a);
    const b: any = [
      ...elements
        .filter(e => e.public)
        .map(e => ({ objectId: e.elementId, name: e.displayName, version: 0 })),
      ...playlists
        .filter(e => e.public)
        .map(e => ({ objectId: e.playlistId, name: e.identifier, version: 0 }))
    ].sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    setPublicObjects(b);
  }

  const downloadCSV = (data, showAll) => {
    const csv = Papa.unparse(
      data.map(a => ({
        Object: a.project,
        User: a.user,
        StartDate: a.date,
        Status: a.status
      })),
      { quotes: true, delimiter: ";" }
    );
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), "sep=;\n", csv]);
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = getDownloadFilename(showAll);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadCSV5 = data => {
    const csv = Papa.unparse(
      data.map(a => ({
        Content: a.project,
        "User Name": a.userName,
        "Due Date": new Date(a.dueDate).toLocaleDateString()
      })),
      { quotes: true, delimiter: ";" }
    );
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), "sep=;\n", csv]);
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = getDownloadFilename(false);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadCustomReport = id => {
    const title = cleanupName(
      customReports.filter(f => f.customReportId === id)[0].title
    );
    const datetime = new Date()
      .toISOString()
      .replace(/[A-Z\-:]/g, "")
      .substring(0, 14);

    getCustomReport(id).then(data => {
      const csv = Papa.unparse(data, { quotes: true, delimiter: ";" });
      const blob = new Blob([
        new Uint8Array([0xef, 0xbb, 0xbf]),
        "sep=;\n",
        csv
      ]);
      const a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = `report_${title}_${datetime}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  const getColorArray = count => {
    if (count > 360) count = 360;
    const a: string[] = [];
    for (let i = 0; i < 360; i += 360 / count) {
      a.push(`hsl(${i}, 75%, 50%)`);
    }
    return a;
  };

  const getDataIncludingNotAttempted = async () => {
    //@ts-ignore
    return (await callApi("reports", getCallUrl(true), { version: "v2" })).data;
  };

  const sendWarningMessage = (user, project) => {
    v3ApiRequest(
      `/projects/${project}/reminders/overdue?user=${user}`,
      "post",
      undefined,
      undefined
    )
      .then(() => message.info("Message is now being processed!"))
      .catch(err =>
        message.error("Could not send message, please try again later.")
      );
  };

  return (
    <AuthContext.Consumer>
      {({ user }) => {
        return (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Overview" key="1">
              <ApiCall
                endpoint="reports"
                query="standard/1"
                options={{ version: "v2" }}
              >
                {({ data, fetching, error }) => {
                  return fetching ? (
                    <div>loading</div>
                  ) : error ? (
                    <Debug data={error} />
                  ) : (
                    <>
                      <Card title="Total User Count">
                        <p>{data.data.countUsers}</p>
                      </Card>
                      <Card title="Percentage of active users in the last 30 days">
                        <p>{data.data.percentageActiveUsers30Days} %</p>
                      </Card>
                      <Card title="Total Content Count">
                        <p>{data.data.countObjects}</p>
                      </Card>
                      <Card title="Total Access Count in the last 30 days">
                        <p>{data.data.countCalls30Days}</p>
                      </Card>
                      <Card title="Top 10 Private Content in the last 30 days">
                        <List
                          dataSource={data.data.items}
                          renderItem={(item: any) => (
                            <List.Item>
                              <Tag color="blue">{item.countCalls30Days}</Tag>{" "}
                              <span>{item.identifier}</span>
                            </List.Item>
                          )}
                        />
                      </Card>
                    </>
                  );
                }}
              </ApiCall>
            </TabPane>
            <TabPane tab="Private Content Details" key="2">
              <Card title="Filter">
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Select an object"
                  onChange={v => setSelectedObject(v)}
                  filterOption={(input, option) =>
                    Array(option.props.children)
                      .join(" ")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {objects !== null &&
                    objects.map(e => (
                      <Option key={e.objectId} value={e.objectId}>
                        {e.name}
                        <i>
                          {e.version > 0 ? ` (Version ${e.version})` : null}
                        </i>
                      </Option>
                    ))}
                </Select>
                <div>&nbsp;</div>
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Select a user"
                  onChange={v => setSelectedUser(v)}
                  filterOption={(input, option) =>
                    Array(option.props.children)
                      .join(" ")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {users !== null &&
                    users.map(e => (
                      <Option key={e.userid} value={e.userid}>
                        {e.email}
                      </Option>
                    ))}
                </Select>
                <div>&nbsp;</div>
                <div>Additional filter:</div>
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Select a group"
                  onChange={v => setSelectedGroup(v)}
                  filterOption={(input, option) =>
                    Array(option.props.children)
                      .join(" ")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {groups !== null &&
                    groups.map(e => (
                      <Option key={e.groupId} value={e.groupId}>
                        {e.groupName}
                      </Option>
                    ))}
                </Select>
                <div>&nbsp;</div>
                <Alert
                  message="Please choose a Content and/or a User and/or a Group to start the search!"
                  type="info"
                  showIcon
                />
              </Card>
              {getCallUrl(false) !== null && (
                <ApiCall
                  endpoint="reports"
                  query={getCallUrl(false) || undefined}
                  options={{ version: "v2" }}
                >
                  {({ data, fetching, error }) => {
                    return fetching ? (
                      <div>loading</div>
                    ) : error ? (
                      <Debug data={error} />
                    ) : (
                      <Table
                        dataSource={data.data}
                        rowKey={(r: any) => r.project + r.user}
                        title={() => (
                          <>
                            <Button
                              type="primary"
                              disabled={data.data.length === 0}
                              onClick={() => downloadCSV(data.data, false)}
                            >
                              Download
                            </Button>
                            <span>&nbsp;</span>
                            <Button
                              disabled={data.data.length === 0}
                              onClick={async () =>
                                downloadCSV(
                                  await getDataIncludingNotAttempted(),
                                  true
                                )
                              }
                            >
                              Download including not attempted
                            </Button>
                          </>
                        )}
                      >
                        <Column title="Content" dataIndex="project" />
                        <Column title="User" dataIndex="user" />
                        <Column title="Start Date" dataIndex="date" />
                        <Column title="Status" dataIndex="status" />
                      </Table>
                    );
                  }}
                </ApiCall>
              )}
            </TabPane>
            <TabPane tab="Public Content" key="3">
              <ApiCall
                endpoint="reports"
                query="standard/3"
                options={{ version: "v2" }}
              >
                {({ data, fetching, error }) => {
                  return fetching ? (
                    <div>loading</div>
                  ) : error ? (
                    <Debug data={error} />
                  ) : (
                    <>
                      <Card title="Usage in the last 10 days">
                        <Bar
                          // @ts-ignore
                          type="ba"
                          data={{
                            labels: data.data.last10Days.map(a => a.date),
                            datasets: [
                              {
                                data: data.data.last10Days.map(
                                  a => a.countCalls
                                ),
                                backgroundColor: getColorArray(1)
                              }
                            ]
                          }}
                          options={{
                            plugins: {
                              legend: {
                                display: false
                              }
                            }
                          }}
                        />
                      </Card>
                      <Card title="Top 10 Content">
                        <Doughnut
                          // @ts-ignore
                          type="doughnut"
                          data={{
                            labels: data.data.top10Titles.map(a => a.title),
                            datasets: [
                              {
                                data: data.data.top10Titles.map(a => a.count),
                                backgroundColor: getColorArray(10)
                              }
                            ]
                          }}
                          options={{
                            plugins: {
                              legend: {
                                position: "bottom",
                                align: "start"
                              }
                            }
                          }}
                        />
                      </Card>
                      <Card title="Usage by type">
                        <Doughnut
                          // @ts-ignore
                          type="doughnut"
                          data={{
                            labels: ["Element", "Playlist"],
                            datasets: [
                              {
                                data: [
                                  data.data.countElements,
                                  data.data.countPlaylists
                                ],
                                backgroundColor: getColorArray(2)
                              }
                            ]
                          }}
                        />
                      </Card>
                      <Card title="Total Access Count">
                        <p>{data.data.countTotal}</p>
                      </Card>
                    </>
                  );
                }}
              </ApiCall>
            </TabPane>
            <TabPane tab="Public Content Details" key="4">
              <ApiCall
                endpoint="reports"
                query={
                  publicContentDetailsObject !== null
                    ? `standard/4/?numDays=${numDays}&filterByObject=${publicContentDetailsObject}`
                    : `standard/4/?numDays=${numDays}`
                }
                options={{ version: "v2" }}
              >
                {({ data, fetching, error }) => {
                  return fetching ? (
                    <div>loading</div>
                  ) : error ? (
                    <Debug data={error} />
                  ) : (
                    <>
                      <Card title="Select number of days for Public Content Details">
                        <Select
                          style={{ width: 200 }}
                          defaultValue={numDays}
                          onChange={v => setNumDays(Number(v))}
                        >
                          <Option key="1" value={10}>
                            10
                          </Option>
                          <Option key="2" value={20}>
                            20
                          </Option>
                          <Option key="3" value={30}>
                            30
                          </Option>
                          <Option key="4" value={60}>
                            60
                          </Option>
                          <Option key="5" value={90}>
                            90
                          </Option>
                          <Option key="6" value={365}>
                            365
                          </Option>
                        </Select>
                      </Card>
                      <Card
                        title={`Usage for Top 10 Content in the last ${numDays} days`}
                        style={{ width: 1000 }}
                      >
                        {data.data.dates !== null ? (
                          <Line
                            // @ts-ignore
                            type="line"
                            data={{
                              labels: [...data.data.dates].reverse(),
                              datasets: data.data.top10.map((a, index) => ({
                                data: [...a.counts].reverse(),
                                label: a.title,
                                backgroundColor: getColorArray(
                                  data.data.top10.length
                                )[index]
                              }))
                            }}
                            options={{
                              plugins: {
                                legend: {
                                  position: "top"
                                }
                              },
                              animation: false,
                              elements: {
                                point: {
                                  radius: 5,
                                  hoverRadius: 7
                                }
                              }
                            }}
                          />
                        ) : (
                          <div>
                            No data found for Top 10 Content in the last{" "}
                            {numDays} days
                          </div>
                        )}
                      </Card>
                      <br />
                      <Card title="Select specific Content for details">
                        <Select
                          showSearch
                          allowClear
                          style={{ width: 200 }}
                          placeholder="Select an object"
                          onChange={v => setPublicContentDetailsObject(v)}
                          filterOption={(input, option) =>
                            Array(option.props.children)
                              .join(" ")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {publicObjects !== null &&
                            publicObjects.map(e => (
                              <Option key={e.objectId} value={e.objectId}>
                                {e.name}
                              </Option>
                            ))}
                        </Select>
                      </Card>
                      <Card
                        title={`Total Count for selected Object in the last ${numDays} days`}
                        style={{ width: 1000 }}
                      >
                        <p>{data.data.selection.totalCount}</p>
                      </Card>
                      <br />
                      <Card style={{ visibility: "hidden" }}>&nbsp;</Card>
                      <Card
                        title={`Usage for selected Content in the last ${numDays} days`}
                        style={{ width: 1000 }}
                      >
                        {data.data.selection.totalCount > 0 ? (
                          <Line
                            // @ts-ignore
                            type="line"
                            data={{
                              labels: data.data.selection.dates
                                .map(a => a.date)
                                .reverse(),
                              datasets: [
                                {
                                  data: data.data.selection.dates
                                    .map(a => a.count)
                                    .reverse(),
                                  backgroundColor: getColorArray(1)[0]
                                }
                              ]
                            }}
                            options={{
                              plugins: {
                                legend: {
                                  display: false
                                }
                              },
                              scales: {
                                yAxis: {
                                  min: 0
                                }
                              },
                              animation: false,
                              elements: {
                                point: {
                                  radius: 5,
                                  hoverRadius: 7
                                }
                              }
                            }}
                          />
                        ) : (
                          <div>
                            No data found for selected Content in the last{" "}
                            {numDays} days
                          </div>
                        )}
                      </Card>
                    </>
                  );
                }}
              </ApiCall>
            </TabPane>
            <TabPane tab="Overdue courses" key="5">
              <Card title="Filter">
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Select a project"
                  onChange={v => setSelectedObject5(v)}
                  filterOption={(input, option) =>
                    Array(option.props.children)
                      .join(" ")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {projects !== null &&
                    projects.map(e => (
                      <Option key={e.projectId} value={e.projectId}>
                        {e.title}
                        <i>
                          {e.version > 0 ? ` (Version ${e.version})` : null}
                        </i>
                      </Option>
                    ))}
                </Select>
                <div>&nbsp;</div>
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Select a user"
                  onChange={v => setSelectedUser5(v)}
                  filterOption={(input, option) =>
                    Array(option.props.children)
                      .join(" ")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {users !== null &&
                    users.map(e => (
                      <Option key={e.userid} value={e.userid}>
                        {e.email}
                      </Option>
                    ))}
                </Select>
                <div>&nbsp;</div>
                <div>Additional filter:</div>
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Select a group"
                  onChange={v => setSelectedGroup5(v)}
                  filterOption={(input, option) =>
                    Array(option.props.children)
                      .join(" ")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {groups !== null &&
                    groups.map(e => (
                      <Option key={e.groupId} value={e.groupId}>
                        {e.groupName}
                      </Option>
                    ))}
                </Select>
                <div>&nbsp;</div>
                <Alert
                  message="Please choose a Content and/or a User and/or a Group to start the search!"
                  type="info"
                  showIcon
                />
              </Card>
              {getCallUrl5() !== null && (
                <ApiCall
                  endpoint="reports"
                  query={getCallUrl5() || undefined}
                  options={{ version: "v2" }}
                >
                  {({ data, fetching, error }) => {
                    return fetching ? (
                      <div>loading</div>
                    ) : error ? (
                      <Debug data={error} />
                    ) : (
                      <Table
                        dataSource={data.data}
                        rowKey={(r: any) => r.project + r.user}
                        title={() => (
                          <Button
                            type="primary"
                            disabled={data.data.length === 0}
                            onClick={() => downloadCSV5(data.data)}
                          >
                            Download
                          </Button>
                        )}
                      >
                        <Column title="Content" dataIndex="project" />
                        <Column title="User Name" dataIndex="userName" />
                        <Column
                          title="Due Date"
                          render={record => (
                            <span>
                              {new Date(record.dueDate).toLocaleDateString()}
                            </span>
                          )}
                        />
                        <Column
                          title=""
                          render={record => (
                            <Button
                              title="Send warning message"
                              onClick={() =>
                                sendWarningMessage(
                                  record.userId,
                                  record.projectId
                                )
                              }
                            >
                              Send warning message
                            </Button>
                          )}
                        />
                      </Table>
                    );
                  }}
                </ApiCall>
              )}
            </TabPane>
            {customReports && customReports.length > 0 && (
              <TabPane tab="Custom Reports" key="6">
                <Card title="Select a Custom Report for download">
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 200 }}
                    placeholder="Select a Custom Report"
                    onChange={v => setSelectedCustomReport(v)}
                    filterOption={(input, option) =>
                      Array(option.props.children)
                        .join(" ")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {customReports !== null &&
                      customReports.map(e => (
                        <Option key={e.customReportId} value={e.customReportId}>
                          {e.title}
                        </Option>
                      ))}
                  </Select>
                  <Button
                    type="primary"
                    disabled={!selectedCustomReport}
                    onClick={() => downloadCustomReport(selectedCustomReport)}
                    style={{ position: "relative", top: -1, marginLeft: 20 }}
                  >
                    Download
                  </Button>
                </Card>
              </TabPane>
            )}
          </Tabs>
        );
      }}
    </AuthContext.Consumer>
  );
};

export const StandardReportBlade = props => {
  return (
    <Blade
      {...props}
      title="Standard Report"
      size="xlarge"
      returnPath={`${props.parentPath}`}
    >
      <StandardReport />
    </Blade>
  );
};
