import * as React from "react";
import { ProtectedRoute } from "../features/shared/ProtectedRoute";

interface BladeRouteProps {
  component: any;
  path: string;
  index?: number;
  parent?: string;
  exact?: boolean;
}

export default ({
  component,
  path,
  index,
  parent,
  exact = false,
  ...rest
}: BladeRouteProps) => {
  const combinedPath = !parent
    ? path
    : parent!.endsWith("/")
    ? `${parent}${path}`
    : `${parent}/${path}`;
  const Component = component;
  return (
    <ProtectedRoute
      path={combinedPath}
      exact={exact}
      render={props => (
        <Component
          {...rest}
          {...props}
          parentPath={parent || combinedPath}
          bladeIndex={index}
        />
      )}
    />
  );
};
