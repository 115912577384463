import * as React from "react";
import { UserInfo } from "./UserInfo";
import { Link } from "react-router-dom";
import { GIcon } from "./GIcon";
import { docsServer } from "../../buildinfo.json";

const devHosts = ["dev.portal.knowledgehub.cloud", "localhost:44373"];

export const EnvironmentDisplay = ({ isDev }) => {
  return isDev ? (
    <div
      style={{
        paddingBottom: "2px",
        textAlign: "center",
        background: "red",
        color: "white"
      }}
    >
      You are currently on the development environment. Things might not work or
      they might break!
    </div>
  ) : null;
};

const TopBarButton = ({ href, external = false, children, title }) => {
  if (external) {
    return (
      <a
        href={href}
        className="topbar-left-border topbar-button"
        target="_blank"
        title={title}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={href} className="topbar-left-border topbar-button" title={title}>
      {children}
    </Link>
  );
};

class TopBarFoldout extends React.Component<{}, { open: boolean }> {
  state = {
    open: false
  };

  render() {
    return (
      <>
        <div className="topbar-left-border topbar-button">
          {this.props.children}
        </div>
        {this.state.open && <div>Kuchen</div>}
      </>
    );
  }
}

export const TopBar = () => {
  const isDev = devHosts.includes(window.location.host);

  return (
    <div
      className="grid-header flex-row space-between"
      style={isDev ? { background: "red" } : {}}
    >
      <div className="topbar">Smart Knowledge Hub</div>
      <EnvironmentDisplay isDev={isDev} />
      <div className="flex-row">
        <TopBarButton href={docsServer} external title="Documentation">
          <GIcon icon="book" classes={["no-line-height"]} />
        </TopBarButton>
        <TopBarButton href="/dashboard" title="Dashboard">
          <GIcon icon="dashboard" classes={["no-line-height"]} />
        </TopBarButton>
        <TopBarButton href="/imprint" title="Imprint">
          <GIcon icon="info" outline classes={["no-line-height"]} />
        </TopBarButton>
        <div className="topbar-left-border">
          <UserInfo />
        </div>
      </div>
    </div>
  );
};
