import { callApi, v3ApiRequest } from "../../api";
import { Element } from "./models/Element";
import { delay, transformLanguage } from "../../utils";
import v4 from "uuid/v4";

const thinxQueryOptions = {
  version: "v1"
};

export const getElements = async () => {
  return await callApi(`thinx`, "elements", {
    method: "get",
    ...thinxQueryOptions
  });
};

export const getElement = async id => {
  return await callApi(`thinx`, "elements/" + id, {
    method: "get",
    ...thinxQueryOptions
  });
};

export const getAllThinx = async () => {
  return await callApi(`thinx`, "thinx", {
    method: "get",
    ...thinxQueryOptions
  });
};

export const getThinx = async id => {
  return await callApi(`thinx`, "thinx/" + id, {
    method: "get",
    ...thinxQueryOptions
  });
};

export const patchElement = async (element: Element) => {
  return await callApi(`thinx`, "element/" + element.elementId, {
    params: element,
    method: "patch",
    ...thinxQueryOptions
  });
};

export const setTagPromoted = async (
  tagId: string,
  thinxId: string,
  promoted: boolean
) => {
  return await callApi(
    `thinx`,
    `promotedtags/${tagId === null ? thinxId : tagId}`,
    {
      params: {
        thinxId,
        tagId,
        promoted
      },
      method: "patch",
      ...thinxQueryOptions
    }
  );
};

export const deleteElement = async (id: string) => {
  return await Promise.all([
    callApi(`thinx`, "elements/" + id, {
      method: "delete",
      ...thinxQueryOptions
    }),
    callApi(`storage`, "thinx/element/" + id, {
      method: "delete",
      ...thinxQueryOptions
    })
  ])[0];
};

export const getAllTags = async () => {
  return await callApi(`thinx`, "tags", {
    method: "get",
    ...thinxQueryOptions
  });
};

export const massDelete = async (ids: string[]) => {
  return await callApi("thinx", "elements", {
    params: ids,
    method: "delete",
    ...thinxQueryOptions,
    sendInBody: true
  });
};

export const massChange = async (ids: string[], operation: string) => {
  return await callApi("thinx", `masschange?operation=${operation}`, {
    params: ids,
    method: "patch",
    ...thinxQueryOptions,
    sendInBody: true
  });
};

export const checkImportData = async (data, type) => {
  let cleanData = data;
  let result = {
    check: [] as any[],
    data: [] as any[],
    type
  };

  if (type == "elements") {
    cleanData = data.map(e => ({
      elementSetId: e.importid,
      displayName: e.name,
      tags: (e.tags || "")
        .split(",")
        .filter(Boolean)
        .map(t => t.trim()),
      contentType: e.type,
      fileUrl: e.url,
      text: e.text,
      language: transformLanguage(e.language),
      imageUrl: e.image,
      weight: e.weight,
      description: e.description,
      customProperties: e.customProperties
    }));

    const apiRes = await callApi("thinx", "elements/import", {
      params: {
        elements: cleanData
      },
      method: "post",
      ...thinxQueryOptions
    });

    return {
      data: cleanData,
      check: apiRes.data,
      type
    };
  } else {
    cleanData = data.map(e => ({
      barcode: e.barcode,
      isDefault: e.default,
      description: e.description,
      imageUrl: e.image,
      language: transformLanguage(e.lang),
      tagName: e.tag,
      title: e.title
    }));
    const apiRes = await callApi("thinx", "thinx/import", {
      params: {
        thinx: cleanData
      },
      method: "post",
      ...thinxQueryOptions
    });
    return {
      data: cleanData,
      check: apiRes.data,
      type
    };
  }
};

export const importElements = async (data, playlist) => {
  return await callApi("thinx", "elements/import", {
    params: {
      elements: data,
      playlist
    },
    method: "patch",
    ...thinxQueryOptions,
    unpack: true
  });
};

export const createElementV3 = async (data, playlist) => {
  // const id = data.get("importId");
  //
  // const random_boolean = Math.random() < 0.5;
  // const random_boolean2 = Math.random() < 0.5;
  //
  // await delay(Math.random() * 1000)
  //
  //
  // return Promise.resolve({
  //     data: {
  //         success: random_boolean,
  //         creationResult: {newContentId: random_boolean2 ? v4() : undefined, uploaded: random_boolean2, importId: id}
  //     }
  // });
  return await v3ApiRequest(
    `/content${playlist != null ? `?playlist=${playlist}` : ""}`,
    "post",
    data,
    undefined
  );
};

export const retryCreateElementV3 = async (data, file, playlist) => {
  const formData = new FormData();

  formData.append("importId", data.importid);
  formData.append("name", data.displayName);
  formData.append("language", data.language);
  formData.append("type", "element");
  formData.append("contentType", data.contentType);
  if (data.description) {
    formData.append("description", data.description);
  }
  if (data.tags.length) {
    formData.append("tags", data.tags);
  }
  formData.append("weight", data.weight || 1);
  if (data.customProperties) {
    formData.append("customProperties", data.customProperties);
  }
  formData.append("file", file);

  const random_boolean = Math.random() < 0.5;
  const random_boolean2 = Math.random() < 0.5;

  await delay(Math.random() * 1000);

  // return Promise.resolve({
  //     data: {
  //         success: random_boolean,
  //         creationResult: {newContentId: v4(), uploaded: random_boolean2, importId: data.importId}
  //     }
  // });
  return await v3ApiRequest(
    `/content${playlist != null ? `?playlist=${playlist}` : ""}`,
    "post",
    data,
    undefined
  );
};

export const getTagDetails = async id => {
  return await callApi("thinx", `tag/${id}/details`, {
    method: "get",
    ...thinxQueryOptions
  });
};

export const patchTagDetails = async (id, data) => {
  return await callApi("thinx", `tag/${id}/details`, {
    params: data,
    method: "patch",
    ...thinxQueryOptions
  });
};

export const deleteTag = async (id: string) => {
  return await callApi(`thinx`, "tag/" + id, {
    method: "delete",
    ...thinxQueryOptions
  });
};

export const getGroupsForElement = async (element, include) =>
  await callApi(
    "/users",
    `groups/?filterByElement=${element}&include=${include}`
  );

export const setElementRelations = async (
  element,
  groups,
  objectType = "element"
) => {
  const data = groups.map(obj => ({
    groupId: obj,
    objectId: element,
    objectType
  }));

  return await callApi("/users", `groups/${element}/relations`, {
    method: "post",
    params: data
  });
};

export const removeElementRelations = async (
  element,
  groups,
  objectType = "element"
) => {
  const data = groups.map(obj => ({
    groupId: obj,
    objectId: element,
    objectType
  }));

  return await callApi("/users", `groups/${element}/relations`, {
    method: "delete",
    params: data,
    sendInBody: true
  });
};

export const uploadFile = async (id, fileData, transscribe = false) => {
  const formData = new FormData();
  formData.append("file", fileData);

  return callApi(
    "storage",
    transscribe
      ? `thinx/content/${id}`
      : `thinx/content/${id}/?transscribe=false`,
    {
      method: "post",
      params: formData,
      asForm: true
    }
  );

  // const random_boolean = Math.random() < 0.5;
  // return Promise.resolve({ success: random_boolean });
};
