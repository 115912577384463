import * as React from "react";
import {
  ApiCallOptions,
  createCancelToken,
  apiRequest,
  v3ApiRequest
} from "../../api";

interface ApiCallProps {
  children?: any;
  endpoint: string;
  query?: string;
  options?: Partial<ApiCallOptions> | null;
  params?: any;
  withoutTenant?: boolean;
  unpackData?: boolean;
  recall?: boolean;
  defaultValue?: any;
}

interface V3ApiOptions {
  defaultValue?: any;
}

export const useV3Api = (
  query,
  method,
  params?,
  options: Partial<V3ApiOptions> = {}
) => {
  const [state, setState] = React.useState({
    error: null,
    data: options.defaultValue || null,
    fetching: true
  });
  const [updateValue, setUpdateValue] = React.useState(0);
  const controller = new AbortController();
  let mounted = true;

  const update = () => {
    setUpdateValue(prev => prev + 1);
  };

  React.useEffect(
    () => {
      v3ApiRequest(query, method, params, controller.signal)
        .then(
          res =>
            mounted &&
            setState({
              fetching: false,
              data: res,
              error: null
            })
        )
        .catch(
          error =>
            mounted &&
            setState({
              data: options.defaultValue || null,
              fetching: false,
              error: error
            })
        );

      return () => {
        mounted = false;
        controller.abort();
      };
    },
    [query, method, params, updateValue]
  );

  return { ...state, update };
};

export const useApi = ({
  endpoint,
  query,
  options,
  params,
  withoutTenant,
  unpackData,
  defaultValue
}: ApiCallProps) => {
  const [state, setState] = React.useState({
    error: null,
    data: defaultValue || null,
    fetching: true
  });
  const cancelToken = createCancelToken();
  let mounted = true;

  React.useEffect(
    () => {
      const callOptions = {
        ...options,
        params: params,
        cancelToken: cancelToken.token
      } as ApiCallOptions;

      apiRequest({
        endpoint,
        query,
        options: callOptions,
        needsTenant: !withoutTenant,
        override: undefined
      })
        .then(
          res =>
            mounted &&
            setState({
              fetching: false,
              data: res.data && unpackData ? res.data : res,
              error: null
            })
        )
        .catch(
          error =>
            mounted &&
            setState({
              data: defaultValue || null,
              error,
              fetching: false
            })
        );

      return () => {
        mounted = false;
        cancelToken.cancel();
      };
    },
    [endpoint, query, params, withoutTenant, unpackData]
  );

  return state;
};

export const ApiCall = (props: ApiCallProps) =>
  React.Children.only(props.children(useApi(props)));
