import * as React from "react";
import { NavLink } from "./NavLink";
import { createFeatureConfigFromTenant } from "../index";
import { isPermitted, AuthContext, Permissions } from "../../auth";
import { GIcon } from "./GIcon";

const filterPermissions = (feature, user) => {
  if (feature.isSubFeature) return false;
  if (!user) return false;
  switch (feature.feature) {
    case "config":
      return isPermitted(
        [
          Permissions.ThinxElements,
          Permissions.Playlists,
          Permissions.ManageProjects
        ],
        user!.claims.permissions
      );
    case "projects":
      return isPermitted(
        [Permissions.ManageProjects, Permissions.PublishProject],
        user!.claims.permissions,
        false
      );
    case "users":
      return isPermitted(
        [
          Permissions.ManageUsers,
          Permissions.ManageGroups,
          Permissions.ManagePermissions
        ],
        user!.claims.permissions,
        false
      );
    case "reporting":
      return user!.claims.permissions && user!.claims.permissions.length > 0;
    case "certificates":
      return isPermitted(
        [Permissions.CertificateTemplates],
        user!.claims.permissions
      );
    case "thinx":
      return isPermitted(
        [Permissions.ThinxElements],
        user!.claims.permissions,
        false
      );
    case "tags":
      return isPermitted([Permissions.TagManagement], user!.claims.permissions);
    case "playlists":
      return false;
    case "integrations":
      return isPermitted(
        [Permissions.ManageIntegrations],
        user!.claims.permissions
      );
    case "platform":
      return (
        user!.claims.tenant == "99d50f4e-a75e-427d-bb8a-12c18133524d" &&
        isPermitted([Permissions.ManageIntegrations], user!.claims.permissions)
      );
    default:
      return user!.claims.permissions && user!.claims.permissions.length > 0;
  }
};

export const Nav = ({ expanded, setExpanded }) => (
  <AuthContext.Consumer>
    {({ user }) => (
      <div className="grid-menu">
        <nav className="navbar">
          <div>
            &nbsp;
            {expanded ? (
              <span
                className="navbar-switch"
                title="Minimize menu"
                onClick={() => setExpanded(false)}
              >
                <GIcon
                  icon="chevron_left"
                  style={{ position: "absolute", top: "1px", left: 0 }}
                />
              </span>
            ) : (
              <span
                className="navbar-switch"
                title="Maximize menu"
                onClick={() => setExpanded(true)}
              >
                <GIcon
                  icon="chevron_right"
                  style={{ position: "absolute", top: "1px", left: "1px" }}
                />
              </span>
            )}
          </div>
          <ul>
            <li key="dashboard" style={{ height: 40 }}>
              <NavLink
                to="/dashboard"
                icon="https://staticfiles.knowledgehub.cloud/portal/features/home_icon.png"
                expanded={expanded}
              >
                Dashboard
              </NavLink>
            </li>
            {createFeatureConfigFromTenant()
              .filter(feature => filterPermissions(feature, user))
              .map(feature => (
                <li key={feature.id}>
                  <NavLink
                    to={feature.path}
                    icon={feature.icon}
                    expanded={expanded}
                  >
                    {feature.name}
                  </NavLink>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    )}
  </AuthContext.Consumer>
);
