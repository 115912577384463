import * as React from "react";
import { AuthContext, TokenTypes, createAuthManager } from "../../auth";
import { Route, Switch, withRouter } from "react-router-dom";
import { ValidToken } from "../../auth/ValidToken";
import LoginCallback from "./LoginCallback";
import { connect } from "react-redux";
import Login from "./Login";
import TenantSetter from "./TenantSetter";
import { receiveUser } from "../../actions";
import { ProtectedRoute } from "./ProtectedRoute";
import { Modal } from "antd";

interface AccessProtectorProps {
  authentication: any;
  history: any;
  userSignedIn: (user: any) => void;
}

const authManager = createAuthManager();

class AccessProtector extends React.Component<AccessProtectorProps> {
  async componentDidMount() {
    const user = authManager.getUser();

    window.addEventListener("loginExpired", this.onLoginExpired);

    if (user !== null) {
      authManager
        .silentSignIn()
        .then(() => {
          this.props.userSignedIn(user);
          this.authenticate(user);
        })
        .catch(err => console.log("token expired"));
    }
  }

  onLoginExpired = () => {
    Modal.error({
      title: "Login Expired",
      content: "You need to login again!",
      okText: "Take me to login",
      okType: "primary",
      onOk: () => authManager.startSignIn()
    });
  };

  authenticate = (user: ValidToken) => {
    let redirectTo = `/@/${user.claims[TokenTypes.tenant]}`;

    if (user === null) {
      redirectTo = "/";
    }

    console.log("authenticate called redirecting to", redirectTo);
    this.props.history.push(redirectTo);
  };

  afterLogin = () => {
    this.authenticate(authManager.getUser()!);
  };

  componentWillUnmount() {
    window.removeEventListener("loginExpired", this.onLoginExpired);
  }

  render() {
    return (
      <AuthContext.Provider value={this.props.authentication}>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route
            path="/login"
            exact
            render={props => (
              <LoginCallback {...props} successCallback={this.afterLogin} />
            )}
          />
          <Route
            path="/logout"
            exact
            render={props => authManager.signOut() || null}
          />
          <ProtectedRoute exact path="/@/:tenant" component={TenantSetter} />
          {this.props.children}
        </Switch>
      </AuthContext.Provider>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    userSignedIn: user => dispatch(receiveUser(user))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccessProtector)
);
