import * as React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { writeTenantData } from "../../utils";
import { Splash } from "./Splash";
import { callApi } from "../../api";

class TenantSetter extends React.Component<
  { location: any; match: any; history: any },
  { isTenantSet: boolean }
> {
  state = {
    isTenantSet: false
  };

  GetCurrentEnv() {
    const devHosts = ["dev.portal.knowledgehub.cloud", "localhost:44373"];
    const testHosts = ["test.portal.knowledgehub.cloud"];
    let currentEnv = "prod";
    if (devHosts.includes(window.location.host)) {
      currentEnv = "dev";
    }
    if (testHosts.includes(window.location.host)) {
      currentEnv = "test";
    }
    return currentEnv;
  }

  async componentDidMount() {
    const { tenant } = this.props.match.params;
    console.log(tenant);
    await writeTenantData(tenant);
    const tokenAuthEnabled = (await callApi(
      "platform",
      `features/tokenauth/${this.GetCurrentEnv()}`,
      undefined,
      undefined,
      false
    )).data.enabled;
    if (tokenAuthEnabled) {
      localStorage.setItem("accessSignature", "");
      localStorage.setItem("thinxAccessSignature", "");
    } else {
      const [res, res2] = await Promise.all([
        callApi("storage", "access"),
        callApi("storage", "thinx/access")
      ]);
      localStorage.setItem("accessSignature", res.sharedAccessSignature);
      localStorage.setItem("thinxAccessSignature", res2.sharedAccessSignature);
    }
    this.setState({
      isTenantSet: true
    });
  }

  render() {
    const { isTenantSet } = this.state;
    return isTenantSet ? <Redirect to="/dashboard" /> : <Splash />;
  }
}

export default withRouter(TenantSetter);
