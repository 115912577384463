import { addQueryParameter, parseUrlHash } from "./urlUtilities";
import * as React from "react";
import { getTenantData as getTenantFromApi, getTenantFeatures } from "../api";
import contentLanguages from "../features/shared/contentLanguages.json";

export function getCookie(name: string): string {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts
      .pop()!
      .split(";")
      .shift()!;
  }
  return "";
}

export const getTenantId = () => {
  return getCookie(".Knowledge.Hub.Tenant");
};

export const writeTenantData = async (tenant: string) => {
  document.cookie = `.Knowledge.Hub.Tenant=${tenant};path=/;domain=knowledgehub.cloud`;
  if (window.location.href.indexOf("localhost") > -1) {
    document.cookie = `.Knowledge.Hub.Tenant=${tenant};path=/;domain=localhost`;
  }
  var tenantData = await getTenantFromApi(tenant);
  var features = await getTenantFeatures(tenant);
  localStorage.setItem(
    ".Knowledge.Hub.Tenant.Data",
    JSON.stringify(tenantData)
  );
  localStorage.setItem(
    ".Knowledge.Hub.Tenant.Features",
    JSON.stringify(features)
  );
};

export const getTenantData = () => {
  return JSON.parse(localStorage.getItem(".Knowledge.Hub.Tenant.Data") || "{}");
};

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const delay = ms => {
  var ctr,
    rej,
    p = new Promise(function(resolve: any, reject: any) {
      ctr = setTimeout(resolve, ms);
      rej = reject;
    });
  /// @ts-ignore
  p.cancel = function() {
    clearTimeout(ctr);
    rej(Error("Cancelled"));
  };
  return p;
};

export const urlUtility = {
  addQueryParameter,
  parseUrlHash
};

// Source: http://stackoverflow.com/questions/497790
export const dateUtils = {
  convert: function(d) {
    // Converts the date in d to a date-object. The input can be:
    //   a date object: returned without modification
    //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
    //   a number     : Interpreted as number of milliseconds
    //                  since 1 Jan 1970 (a timestamp)
    //   a string     : Any format supported by the javascript engine, like
    //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
    //  an object     : Interpreted as an object with year, month and date
    //                  attributes.  **NOTE** month is 0-11.
    if (d == null || !d) {
      return NaN;
    }

    return d.constructor === Date
      ? d
      : d.constructor === Array
      ? new Date(d[0], d[1], d[2])
      : ///@ts-ignore
      d.constructor === Number
      ? ///@ts-ignore
        new Date(d)
      : ///@ts-ignore
      d.constructor === String
      ? ///@ts-ignore
        new Date(d)
      : typeof d === "object"
      ? new Date(d.year, d.month, d.date)
      : null;
  },
  compare: function(a: any, b: any) {
    // Compare two dates (could be of any type supported by the convert
    // function above) and returns:
    //  -1 : if a < b
    //   0 : if a = b
    //   1 : if a > b
    // NaN : if a or b is an illegal date
    // NOTE: The code inside isFinite does an assignment (=).

    if (Number.isNaN(a) || this.convert(a) == null) {
      return -1;
    }

    if (Number.isNaN(b) || this.convert(b) == null) {
      return 1;
    }

    return isFinite((a = this.convert(a)!.valueOf())) &&
      isFinite((b = this.convert(b)!.valueOf()))
      ? ((a > b) as any) - ((a < b) as any)
      : NaN;
  }
};

export function useEventListener(eventName, callback) {
  React.useEffect(
    () => {
      window.addEventListener(eventName, callback);
      return () => window.removeEventListener(eventName, callback);
    },
    [eventName, callback]
  );
}

export function sendEvent(eventName) {
  const event = new CustomEvent(eventName);
  window.dispatchEvent(event);
}

const langShorts = Object.keys(contentLanguages);

export function transformLanguage(language) {
  let langToUse = language;
  if (language.length == 5) {
    langToUse = language.substring(0, 2);
  }

  if (langShorts.indexOf(langToUse) == -1) {
    return null;
  }

  return langToUse;
}
