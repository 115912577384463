import * as React from "react";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, Icon } from "antd";
import { createFeatureConfigFromTenant } from "./features";
import { capitalizeFirstLetter } from "./utils";

let featuresMap: any[] = [];

export const BreadcrumbView = withRouter((props: any) => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((path, index) => {
    let name;
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    if (featuresMap.length > 0) {
      const feature = featuresMap.filter(f => f.feature.indexOf(path) === 0);
      if (feature.length > 0) {
        name = feature[0].name;
      }
    } else {
      featuresMap = createFeatureConfigFromTenant();
    }

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{name || capitalizeFirstLetter(path)}</Link>
      </Breadcrumb.Item>
    );
  });

  return (
    <Breadcrumb className="kh-breadcrumb" separator=">">
      <Breadcrumb.Item key="home">
        <Link to="/dashboard">
          <Icon type="home" />
        </Link>
      </Breadcrumb.Item>
      {extraBreadcrumbItems}
    </Breadcrumb>
  );
});
