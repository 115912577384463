import { callApi } from "../../api";
import { PatchOperation } from "../../utils/patchOperations";
import { Project, ProjectManagementData } from "./models";
import { ProjectDetails } from "./models/Project";

const projectQueryOptions = {
  version: "v2"
};

const patchProjectBase = (id, patchOps) => {
  return callApi("projects", "management/" + id, {
    params: patchOps,
    method: "patch",
    ...projectQueryOptions,
    headers: {
      "Content-Type": "application/json-patch+patch"
    }
  });
};

export const getProjectList = async () => {
  return (await callApi("projects", "management", {
    method: "get",
    ...projectQueryOptions,
    unpack: true
  })) as Promise<{ data: ProjectManagementData[] }>;
};

export const getProject = async id => {
  var result = await callApi("projects", "management/" + id, {
    method: "get",
    ...projectQueryOptions
  });
  return result.data as ProjectDetails;
};

export const updateProject = async (id: string, patch: PatchOperation[]) => {
  return await patchProjectBase(id, patch);
};

export const deleteProject = async id => {
  return await callApi("projects", `management/${id}?type=project`, {
    method: "delete",
    ...projectQueryOptions
  });
};

export const createProject = async (projectData: Project) => {
  var res = await callApi("projects", "", {
    method: "post",
    params: projectData,
    ...projectQueryOptions
  });

  return res;
};

export const getGroupsForProject = async (project, include) =>
  await callApi(
    "/users",
    `groups/?filterByProject=${project}&include=${include}`
  );

export const setProjectRelations = async (
  project,
  groups,
  objectType = "project"
) => {
  const data = groups.map(obj => ({
    groupId: obj,
    objectId: project,
    objectType
  }));

  return await callApi("/users", `groups/${project}/relations`, {
    method: "post",
    params: data
  });
};

export const removeProjectRelations = async (
  project,
  groups,
  objectType = "project"
) => {
  const data = groups.map(obj => ({
    groupId: obj,
    objectId: project,
    objectType
  }));

  return await callApi("/users", `groups/${project}/relations`, {
    method: "delete",
    params: data,
    sendInBody: true
  });
};

export const createBareBonesObject = async () => {
  return await callApi("projects", "management", {
    method: "post",
    version: "v2",
    unpack: true
  });
};

export const publishProject = async (id: string) => {
  return await patchProjectBase(id, [
    {
      op: "add",
      path: "/published",
      value: true
    }
  ]);
};

export const unpublishProject = async (id: string) => {
  await patchProjectBase(id, [
    {
      op: "add",
      path: "/published",
      value: false
    }
  ]);
};

export const getStartUrl = async (id: string, lang?: string) => {
  return await callApi(
    `projects`,
    "management/" +
      id +
      "/starturl" +
      `${lang && lang != null ? "?lang=" + lang : ""}`,
    {
      method: "get",
      ...projectQueryOptions
    }
  );
};

export const createCopy = async (projectId: string) => {
  return await callApi(`projects`, `management/${projectId}/copy`, {
    method: "post",
    ...projectQueryOptions
  });
};

export const changeActiveProjectVersion = async (
  projectId: string,
  newId: string
) => {
  return await callApi(`projects`, `management/${projectId}/change/${newId}`, {
    method: "post",
    ...projectQueryOptions
  });
};
