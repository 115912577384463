import { callApi } from "../../api";
import { Playlist } from "./models/Playlist";
import { PlaylistContent } from "./models/PlaylistContent";

const playlistQueryOptions = {
  version: "v2"
};

export const getPlaylist = async (playlistId: string) => {
  return await callApi(`playlists`, "/" + playlistId, {
    method: "get",
    ...playlistQueryOptions
  });
};

export const getPlaylists = async () => {
  return await callApi(`playlists`, "", {
    method: "get",
    ...playlistQueryOptions
  });
};

export const patchPlaylist = async (playlist: Playlist) => {
  return await callApi(`playlists`, "/" + playlist.playlistId, {
    params: playlist,
    method: "patch",
    ...playlistQueryOptions
  });
};

export const getGroupsForPlaylist = async (playlist, include) =>
  await callApi(
    "/users",
    `groups/?filterByPlaylist=${playlist}&include=${include}`
  );

export const setPlaylistRelations = async (
  playlist,
  groups,
  objectType = "playlist"
) => {
  const data = groups.map(obj => ({
    groupId: obj,
    objectId: playlist,
    objectType
  }));

  return await callApi("/users", `groups/${playlist}/relations`, {
    method: "post",
    params: data
  });
};

export const removePlaylistRelations = async (
  playlist,
  groups,
  objectType = "playlist"
) => {
  const data = groups.map(obj => ({
    groupId: obj,
    objectId: playlist,
    objectType
  }));

  return await callApi("/users", `groups/${playlist}/relations`, {
    method: "delete",
    params: data,
    sendInBody: true
  });
};
