import * as React from "react";
import { AuthContext, TokenTypes } from "../../auth";
import { GIcon } from "./GIcon";
import { OnClickOutside } from "./OnClickOutside";
import { Link } from "react-router-dom";

const UserContextMenu = ({ claims, action, open }) =>
  open ? (
    <OnClickOutside action={action}>
      <div className="user-context-menu">
        <Link to="/logout">Logout</Link>
      </div>
    </OnClickOutside>
  ) : null;

interface State {
  open: boolean;
}

export class UserInfo extends React.Component<{}, State> {
  state = {
    open: false
  };

  toggleUserMenu = () => {
    this.setState(prevState => {
      return {
        open: !prevState.open
      };
    });
  };

  render() {
    const { open } = this.state;

    return (
      <AuthContext.Consumer>
        {authContext => (
          <div className="topbar-userinfo-container">
            <div className="topbar-userinfo" onClick={this.toggleUserMenu}>
              <span>
                {authContext.user &&
                  (authContext.user.claims.name ||
                    authContext.user.claims.email)}
              </span>
              <GIcon icon="assignment_ind" />
            </div>
            {authContext.user && (
              <UserContextMenu
                claims={authContext.user.claims}
                action={this.toggleUserMenu}
                open={open}
              />
            )}
          </div>
        )}
      </AuthContext.Consumer>
    );
  }
}
