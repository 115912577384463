import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import { AuthContext } from "../../auth";

export const DeepLink = ({
  to,
  external,
  children,
  title
}: {
  to: string;
  external?: boolean;
  children?: any;
  title?: string;
}) => {
  if (external) {
    return (
      <a href={to} target="_blank" title={title}>
        {children}
      </a>
    );
  }

  localStorage.setItem("lastLocation", to);

  return (
    <Link to={to} title={title}>
      {children}
    </Link>
  );
};

export const DeepLinker = props => {
  const redirect = localStorage.getItem("lastLocation");
  let RedirectToComponent: any = null;
  if (redirect !== null) {
    RedirectToComponent = <Redirect to={redirect} />;
  }

  return (
    <AuthContext.Consumer>
      {auth =>
        auth.isAuthenticated && auth.user ? (
          <RedirectToComponent />
        ) : (
          <Redirect to="/" />
        )
      }
    </AuthContext.Consumer>
  );
};
