import * as React from "react";
import { BladeWorks } from "../../Blades/BladeWorks";
import BladeRoute from "../../Blades/BladeRoute";
import { Dashboard } from "../../Dashboard";
import { BreadcrumbView } from "../../BreadcrumbView";

import { Switch, Route } from "react-router-dom";
import { Imprint } from "./Imprint";

import { createFeatureConfigFromTenant } from "../index";
import { ProtectedRoute } from "./ProtectedRoute";

export const PortalContent = () => (
  <div className="grid-content">
    <BreadcrumbView />
    <Switch>
      <ProtectedRoute exact path="/dashboard" component={Dashboard} />
      <Route exact path="/imprint" component={Imprint} />
      <React.Suspense fallback={<div>Loading...</div>}>
        <BladeWorks>
          {createFeatureConfigFromTenant().map((feature, index) => (
            <BladeRoute
              key={feature.id}
              path={feature.path}
              component={feature.Component}
              index={index}
            />
          ))}
        </BladeWorks>
      </React.Suspense>
    </Switch>
  </div>
);

export default PortalContent;
