import * as React from "react";

interface OnClickOutsideProps {
  action: any;
  children: any;
}

export const OnClickOutside = ({ action, children }: OnClickOutsideProps) => {
  const nonPropAction = e => {
    e.stopPropagation();
    action();
  };

  return (
    <>
      <div className="outside-blocker" onClick={nonPropAction}>
        &nbsp;
      </div>
      <div style={{ zIndex: 100 }}>{children}</div>
    </>
  );
};
