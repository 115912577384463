import { callApi } from "../../api";

const reportQueryOptions = {
  version: "v2"
};

export const getCustomReports = async () => {
  return await callApi("reports", "custom", {
    method: "get",
    ...reportQueryOptions
  });
};

export const getCustomReport = async id => {
  return await callApi("reports", `custom/${id}`, {
    method: "get",
    ...reportQueryOptions
  });
};
