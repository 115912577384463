import * as React from "react";

interface TenantData {
  id: string;
  featureConfig: TenantFeatureConfig[];
  organisation?: string;
  mostImportantItem: string;
  properties?: any;
}

interface TenantFeatureConfig {
  key: string;
  name: string;
  enabled: boolean;
}

const defaultValue: Partial<TenantData> = {};

export const TenantContext = React.createContext(defaultValue);
