import * as React from "react";
import { BladePlaceholder } from "./Blade";
import { useBladeContext } from "./BladeWorks";

const bladeSizes = {
  small: 315,
  medium: 585,
  large: 855,
  xlarge: 0
};

export interface ArsenalChildrenProps {
  size: "small" | "medium" | "large" | "xlarge";
}

const Arsenal = ({ children }: { children: any }) => {
  const context = useBladeContext();

  const [width, setWidth] = React.useState(0);
  React.useLayoutEffect(
    () => {
      const updateWidth = () => {
        const elem = document.querySelector(".blade-works-layout")!;
        elem.scrollLeft = elem.scrollWidth;
        setWidth(window.innerWidth);
      };
      window.addEventListener("resize", updateWidth);
      updateWidth();
      return () => window.removeEventListener("resize", updateWidth);
    },
    [setWidth]
  );

  const childSizes = context.blades.map(blade => {
    if (blade == null) return null;
    return blade.size;
  });

  const calculateSize = size => {
    let cumulated = 0;
    for (let i = childSizes.length - 1; i >= 0; --i) {
      if (
        bladeSizes[childSizes[i]] + cumulated > size ||
        bladeSizes[childSizes[i]] === 0
      ) {
        return cumulated;
      }
      cumulated += bladeSizes[childSizes[i]];
    }
    return cumulated;
  };

  const size = calculateSize(width - 200);

  return (
    <>
      {children}
      {size > 0 && <BladePlaceholder size={size} />}
    </>
  );
};

export default Arsenal;
