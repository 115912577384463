import * as React from "react";

export const Imprint = () => (
  <div className="legalDoc">
    <div className="legalDocPositioning">
      <strong>Smart Knowledge Hub</strong>
      <br />
      © 2020 - present | Pokeshot GmbH
      <br />
      <p>
        <br />
        Pokeshot GmbH
        <br />
        Rudower Chaussee 17
        <br />
        12489 Berlin
        <br />
        Germany
        <br />
      </p>
      <p>
        <strong>Contact</strong>
        <br />
        Tel.: +49 30 6392 7730
        <br />
        eMail: info@pokeshot.com
        <br />
      </p>
      <p>
        <br />
        <strong>Vertretungsberechtigte</strong>
        <br />
        Stephan Müller-Ziebur
        <br />
        <br />
        Handelsregister
        <br />
        HRB 179521 B – Berlin
        <br />
        <br />
        Ust-ID-Nr
        <br />
        DE 281 767 036
        <br />
      </p>
    </div>
  </div>
);
